.navigation_line {
  background-color: var(--color-text);
  width: 90%;
  max-width: 1360px;
  height: 1px;
  margin: 0 auto;
}

.navigation_list {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 30px auto;
  padding: 0;
  width: fit-content;
}

.navigation_list li {
  font-weight: normal;
  font-size: 1.1em;
  list-style: none;
  margin: 15px 25px;
  border-bottom: 2px solid rgba(0,0,0,0);
}

.navigation_list li a {
  text-decoration: none;
  color: var(--color-text);
}

.navigation_list li:hover {
  font-weight: normal;
  color: var(--color-dark-blue);
  border-bottom: 2px solid var(--color-light-blue);
}

.navigation_button {
  display: none;
}

/* Tablet Start */
@media only screen and (max-width: 1280px) {

  .navigation_button {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    background-color: var(--color-dark-blue);
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 100px;
    z-index: 400;
  }

  .navigation_button p {
    width: fit-content;
    margin: 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    pointer-events: none;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    margin: 5px;
    align-items: center;
    justify-content: center;
  }

  .hamburger_element {
    height: 4px;
    width: 30px;
    background-color: white;
    margin: 2px 0px;
    border-radius: 10px;
  }

  .navigation_list_wrapper {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row-reverse;
    align-items: center;
    background-image: var(--grad);
    height: 100vh;
    width: 400px;
    z-index: 200;
    right: 0;
    margin: 0;
  }

  .navigation_list {
    flex-direction: column;
    margin: 20px;
  }

  .navigation_line {
    background-color: var(--color-text);
    height: 80%;
    width: 1px;
    margin: auto 0;
  }

}

/* Mobile Start */
@media only screen and (max-width: 600px) {

  .navigation_list_wrapper {
    height: 100vh;
    width: 100%;
    justify-content: flex-end;
  }

  .navigation_line {
    margin-left: 40px;
  }

}
