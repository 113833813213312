:root {
  /* Colors */
  --color-dark-blue: #273750;
  --color-light-blue: #bdcce2;
  --color-text: #2e2e2e;
  --color-footer: #f2f2f2;
  --grad: linear-gradient(to bottom right, #eff5ff, #f2f7fd);
}

.App {
  overflow: hidden;
}

.top_section {
  display: flex;
  flex-direction: column;
  flex-flow: column;
}

.list {
  line-height: 1.8;
  list-style-type: circle;
  padding-left: 20px;
}

.section_parent p {
  line-height: 1.6;
  margin: 20px 0px;
}

.section_parent p a {
  text-decoration: underline;
  color: var(--color-dark-blue);
}

h1,
h2 {
  color: var(--color-dark-blue);
}

/* Landing Section */
p span {
  font-weight: bold;
}

.landing_section_text {
  margin-bottom: 100px;
}

/* Tablet Start */
@media only screen and (max-width: 1280px) {
  .top_section {
    flex-flow: column-reverse;
  }

  .landing_section_text {
    margin-bottom: 50px;
  }
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

body {
  -webkit-overflow-scrolling: auto;
  -ms-overflow-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landing_hint {
  max-width: 100%;
  background-color: #fbd9df;
  color: #d55e6f;
  padding: 10px;
  border-radius: 4px;
}
.terminbuchung-overlay {
  top: 80px !important;
  z-index: 300 !important;
}

/* Mobile Start */
@media only screen and (max-width: 600px) {
  .terminbuchung-overlay {
    top: 105vh !important;
    z-index: 300 !important;
  }
}
